<template>
  <div class="navbar">
    <el-menu
      :default-openeds="openeds"
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      ref="menus"
      style="font-size: 20px"
      router
    >
      <div v-for="title in showMenu" :key="title.name">
        <el-submenu :index="title.index + ''">
          <template #title>
            <router-link :to="title.to" style="margin-left: 20px">{{
              title.name
            }}</router-link>
          </template>
          <div v-if="title.child != null">
            <div v-for="subtitle in title.child" :key="subtitle.name">
              <el-menu-item :index="subtitle.to">{{
                subtitle.name
              }}</el-menu-item>
            </div>
          </div>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    // this.role=localStorage.getItem('role')

    return {
      // superadmin: false,
      // admin: false,
      // teacheradmin: false,
      // guestadmin: false,
      openeds: ["6"],
      uniqueOpened: false,
      role: 0,
      showMenu: [],
      menu: [
        {
          name: "首页图片",
          to: "/layout/homePicture",
          index: "1",
          power: [0],
        },
        {
          name: "公司荣誉", //学生个人信息管理
          to: "/layout/compGlory",
          index: "2",
          power: [0],
        },
        {
          name: "涉外代理", //教师、普通管理员、超管个人信息管理
          to: "/layout/foreAgent",
          index: "3",
          power: [0],
        },

        {
          name: "公司概况",
          to: "/layout/compProfile",
          index: "4",
          power: [0],
        },
        {
          name: "服务领域",
          to: "/layout/servScope",
          index: "5",
          power: [0],
        },
        {
          name: "专利代理人",
          to: "/layout/execDirector",
          index: "6",
          power: [0],
          child: [
            {
              name: "执行董事",
              to: "/layout/execDirector",
              index: "61",
            },
            {
              name: "专业代理人",
              to: "/layout/proAgent",
              index: "62",
            },
          ],
        },
        {
          name: "新闻动态",
          to: "/layout/newsTrends",
          index: "7",
          power: [0],
        },
        {
          name: "法律支持",
          to: "/layout/legalSupport",
          index: "8",
          power: [0],
        },
        {
          name: "联系方式",
          to: "/layout/contactWay",
          index: "9",
          power: [0],
        },
        {
          name: "首页页脚",
          to: "/layout/pageFoot",
          index: "10",
          power: [0],
        },
      ],
    };
  },
  methods: {
    // 如果不对,可删除：获取登陆者role
    setShowMenu(role) {
      this.showMenu = this.menu.filter((item) => {
        return item.power.includes(role);
      });
    },
  },
  mounted() {
    // 如果不对,可删除：获取登陆者role
    this.role = localStorage.getItem("role");
    this.setShowMenu(parseInt(this.role));
  },
};
</script>

<style lang="less" scoped>
.navbar {
  background: rgba(238, 235, 235, 0.5);
  display: flex;
  text-align: left;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  width: 250px;
}
.title-sytle {
  background-color: #6db6ff;
  color: white;
  padding-top: 5px;
  width: 100%;
}
.el-submenu /deep/ .el-submenu__title {
  font-size: 19px;
  font-weight: bold;
  .el-submenu__icon-arrow {
    opacity: 0;
  }
}
.el-submenu .el-menu-item {
  font-size: 17px;
  font-weight: normal;
}

/* .sub-style {
    background-color: white;
    margin: 20px;
  } */
a {
  text-decoration: none;
  color: #303133;
}

.router-link-active {
  color: #303133;
  text-decoration: none;
}
</style>


